<template>
  <div class="container">
    <div class="content">欢迎您使用皮可米！</div>
    <div class="content">
      为了更好地为您提供服务，请您仔细阅读《皮可米用户协议》（以下简称“本协议”），本协议是您（以下也可用“用户”来指代您）与就使用“皮可米”产品所达成的协议。在您开始使用“皮可米”产品之前，请您务必认真阅读并充分理解本协议，（如您是未成年人，即未满18周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，并取得法定监护人的同意），特别是涉及免除或者限制皮可米责任的条款、开通和使用特殊单项服务的条款。您理解皮可米无义务对本用户协议事项进行任何形式的审查和确认。皮克米谨此提醒家长合理引导未成年子女的上网及消费行为，切实履行监护义务。
    </div>
    <div class="content">
      请您仔细阅读以下条款，如果您对本协议的任何条款表示异议，您可以选择不进入“皮可米”或者停止使用“皮可米”产品。当您注册成功，无论是进入“皮可米”，还是在“皮可米”产品上发布任何内容（包括评论、图片等），均意味着您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得皮可米对您问询的解答等理由，主张本协议无效或要求撤销本协议。如果您是非中国大陆地区的用户，您订立或履行本协议以及使用本服务的行为还需要同时遵守您所属和/或所处国家或地区的法律。
    </div>
    <div class="title big">1、协议适用范围</div>
    <div class="content">
      1.1 本协议约定了皮可米和用户之间就使用“皮可米”产品及服务事宜发生的权利义务关系。
    </div>
    <div class="content">
      1.2 用户指所有直接或间接获取和使用“皮可米”产品及相关服务的用户。
    </div>
    <div class="content">
      1.3
      “皮可米”产品指由杭州隅星互联网科技有限责任公司合法拥有并运营的，标注名称为“皮可米”的客户端应用程序。
    </div>
    <div class="content">
      1.4本协议内容包括本协议正文及所有我们已经发布或将来可能发布的隐私权政策、各项政策、规则、声明、通知、警示、提示、说明（以下简称“规则”）。前述规则为本协议不可分割的组成部分，与本协议具有同等法律效力。
    </div>
    <div class="title big">2、使用“皮可米”产品</div>
    <div class="content">
      2.1 用户可以从任何合法的渠道下载“皮可米”软件程序到其合法拥有的终端设备中。但除非得到特别的授权，否则，用户不得以任何形式改编、复制或交易“皮可米”软件程序。
    </div>
    <div class="content">
      2.2 一旦用户在其终端设备中打开“皮可米”，即视为使用“皮可米”产品。为充分实现“皮可米”的全部功能，用户可能需要将其终端设备联网。
    </div>
    <div class="content">
      2.3
      “皮可米”授权用户拥有中华人民共和国境内永久地、不可转让的、非独占地和非商业性地使用“皮可米”产品的权利，但该权利不可转让，皮可米也保留在任何必要情形下收回该授权的权利。
    </div>
    <div class="title">3、关于“账号”</div>
    <div class="content">
      3.1
      “皮可米”需要用户使用有效的手机号或邮箱号作为自己的账号，初次使用登录完成即表示注册成功。皮可米会按系统规则自动分配给用户一个号码，该号码即为皮可米账号。你可以通过第三方账号注册和绑定皮可米账号（必须实名手机号绑定）。
    </div>
    <div class="content">
      3.2 您理解并承诺，您所设置的账号不得违反国家法律法规及皮可米的相关规则，您的账号姓名、头像和简介等信息中不得出现违法和不良信息，不得冒用他人名义（包括但不限于冒用他人姓名、名称字号、头像等足以让人引起混淆的方式）开设账号。您在账号注册及使用过程中需遵守法律法规、不得违反社会主义制度，不得侵犯国家利益、公民、法人合法权益，不得违背公共秩序、社会道德风尚，并保证所有信息的真实性。否则，皮可米有权对您的账号进行暂停使用或注销等处理。
    </div>
    <div class="content">
      3.3 您在“皮可米”中的注册账号仅限于您本人使用，禁止赠与、借用、出租或售卖。如果皮可米发现或者有理由怀疑使用者并非账号初始注册人，则有权在未经通知的情况下，暂停或终止向该注册账号提供服务，并有权注销该账号，而无需向注册该账号的用户承担法律责任。由此带来的包括但不限于用户通讯中断、用户资料和信息清空等损失由用户自行承担。
    </div>
    <div class="content">
      3.4 用户有责任维护个人账号、密码的安全性与保密性，用户就以其注册账号名义所从事的一切活动负全部责任，包括用户数据的修改、发表的言论以及其他在“皮可米”上的操作行为。因此，用户应高度重视对账号与密码的保密，若发现他人未经许可使用其账号或发生其他任何安全漏洞问题时立即通知皮可米。因用户个人原因发生的密码泄露，所产生的损失应由用户自行承担。
    </div>
    <div class="content">
      3.5 您使用皮可米产品过程中，充分理解并同意：本服务中可能包括皮可米针对个人或企业推出的信息、广告发布或品牌推广服务，您同意皮可米有权在皮可米产品及服务中展示皮可米相关和/或第三方供应商、合作伙伴的商业广告或商业信息。如您不同意皮可米推送通知服务，您有权自行关闭该服务或可停止使用皮可米产品及其相关服务。
    </div>
    <div class="content">
      3.6为了充分利用皮可米账号资源，如果用户存在长期未登陆使用皮可米账号的情形，皮可米有权对账号进行注销、回收、替换或采取删除该账户在软件数据库中的任何记录（包括但不限于注册信息、虚拟礼物信息等）等清理措施，相应服务同时终止。皮可米在对此类账户进行清理前，将以包括但不限于弹窗、网站公告、站内消息、客户端推送信息等方式通知用户。 
    </div>
    <div class="title big">4、权利和义务</div>
    <div class="title">4.1 行为要求</div>
    <div class="content">
      您应当对您使用皮可米产品和服务的行为负责，除非法律允许或者经皮可米事先书面许可，您使用皮可米产品和服务不得具有下列行为：
    </div>
    <div class="content">
      4.1.1 使用皮可米授权或许可的任何插件、外挂、系统或第三方工具对本服务的正常运行进行干扰、破坏、修改或施加其他影响。
    </div>
    <div class="content">
      4.1.2 利用或针对皮可米产品和服务进行任何危害计算机网络安全的行为，包括但不限于：
    </div>
    <div class="content">
      （1）非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；
    </div>
    <div class="content">
      （2）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；
    </div>
    <div class="content">
      （3）明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；
    </div>
    <div class="content">
      （4）使用未经许可的数据或进入未经许可的服务器/账号；
    </div>
    <div class="content">
      （5）未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；
    </div>
    <div class="content">
      （6）未经许可，企图探查、扫描、测试本服务系统或网络的弱点或其它实施破坏网络安全的行为；
    </div>
    <div class="content">
      （7）企图干涉、破坏本服务系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；
    </div>
    <div class="content">（8）伪造TCP/IP数据包名称或部分名称。</div>
    <div class="content">
      4.1.3 对皮可米产品和服务进行反向工程、反向汇编、编译或者以其他方式尝试发现本服务的源代码。
    </div>
    <div class="content">
      4.1.4 恶意注册皮可米账号，包括但不限于频繁、批量注册账号。
    </div>
    <div class="content">
      4.1.5 违反法律法规、本协议相关规则及侵犯他人合法权益的其他行为。在任何情况下，如果皮可米有理由认为您的任何行为违反或可能违反上述约定的，皮可米可独立进行判断并处理，且在任何时候有权在不进行任何事先通知的情况下终止向皮可米用户提供服务，并追究相关责任。
    </div>
    <div class="title">4.2 信息内容权利及规范</div>
    <div class="content">
      4.2.1 用户按规定可以注册账号，使用皮可米产品和服务。请您保证对在通过皮可米平台制作、复制、上传、发布、传播、评论的任何内容（包括但不限于头像、名称、账号介绍，或文字、图片、音频、视频、链接等，以及其他使用皮可米平台服务所产生的其他内容）享有合法权益，若您账号中的内容发生权利纠纷或侵犯了任何第三方的合法权益，需您承担全部法律责任。
    </div>
    <div class="content">
      4.2.2 您的制作、复制、上传、发布、传播、评论等内容应自觉遵守宪法法律、法规、遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。您不得制作、复制、上传、发布、传播法律、行政法规禁止的下列信息内容：
    </div>
    <div class="content">（1）反对宪法确定的基本原则的；</div>
    <div class="content">（2）危害国家安全，泄露国家秘密的；</div>
    <div class="content">
      （3）颠覆国家政权，推翻社会主义制度、煽动分裂国家、破坏国家统一的；
    </div>
    <div class="content">（4）损害国家荣誉和利益的；</div>
    <div class="content">（5）宣扬恐怖主义、极端主义的；</div>
    <div class="content">（6）宣扬民族仇恨、民族歧视，破坏民族团结的；</div>
    <div class="content">（7）煽动地域歧视、地域仇恨的；</div>
    <div class="content">（8）破坏国家宗教政策，宣扬邪教和迷信的；</div>
    <div class="content">
      （9）编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；
    </div>
    <div class="content">
      （10）散布、传播暴力、淫秽、色情、赌博、凶杀、恐怖或者教唆犯罪的；
    </div>
    <div class="content">
      （11）侵害未成年人合法权益或者损害未成年人身心健康的；
    </div>
    <div class="content">
      （12）未获他人允许，偷拍、偷录他人，侵害他人合法权利的；
    </div>
    <div class="content">
      （13）包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的；
    </div>
    <div class="content">
      （14）危害网络安全、利用网络从事危害国家安全、荣誉和利益的；
    </div>
    <div class="content">（15）侮辱或者诽谤他人，侵害他人合法权益的；</div>
    <div class="content">（16）对他人进行暴力恐吓、威胁，实施人肉搜索的；</div>
    <div class="content">（17）涉及他人隐私、个人信息或资料的；</div>
    <div class="content">（18）散布污言秽语，损害社会公序良俗的；</div>
    <div class="content">
      （19）侵犯他人隐私权、名誉权、肖像权、知识产权、商业秘密等合法权益内容的；
    </div>
    <div class="content">
      （20）未经皮可米许可，利用本服务为自己或第三方进行推广、发布广告的（包括但不限于加入第三方链接、广告等行为）；
    </div>
    <div class="content">
      （21）过度营销信息，骚扰信息和/或垃圾信息、低俗类信息、垃圾广告的；
    </div>
    <div class="content">
      （22）使用本网站常用语言文字以外的其他语言文字制作、复制、上传、发布、传播的内容、留言、评论的；
    </div>
    <div class="content">
      （23）与所制作、复制、上传、发布、传播的内容、留言、评论的信息毫无关系的；
    </div>
    <div class="content">
      （24）所发布、传播的内容毫无意义的，或刻意使用字符组合以逃避技术审核的；
    </div>
    <div class="content">
      （25）其他违反法律法规、政策及公序良俗、干扰皮可米平台正常运营或侵犯其他用户或第三方合法权益内容的其他信息。
    </div>
    <div class="title">
      4.3 为确保公司和用户的利益，请您在使用皮可米产品和服务时，不要进行如下行为（该行为是指使用皮可米账号所进行的任何行为，包括但不限于注册登录、账号运营、管理及推广以及其他行为）；
    </div>
    <div class="content">
      4.3.1 提交、发布虚假信息，或冒充、利用他人名义进行相关活动；
    </div>
    <div class="content">
      4.3.2 强制、诱导其他用户关注、点击链接页面或分享信息；
    </div>
    <div class="content">4.3.3 虚构事实、隐瞒真相以误导、欺骗他人；</div>
    <div class="content">4.3.4 利用皮可米平台及账号从事违法犯罪活动；</div>
    <div class="content">
      4.3.5 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播；
    </div>
    <div class="content">
      4.3.6 其他违反法律法规规定、侵犯其他用户合法权益、干扰产品正常运营或未经公司明示授权的行为。
    </div>
    <div class="title">
      4.4 若您需对皮可米平台内容创作衍生品或投放商业广告，请您另外提交书面授权申请，在符合条件且得到公司同意后，您方可通过该平台进行广告或推广等商业活动。
    </div>
    <div class="title">
      4.5 皮可米设立公众投诉、举报平台，用户可按照皮可米公示的投诉举报制度向皮可米投诉、举报各类违法违规行为、违法传播活动、违法有害信息等内容，公司将及时受理和处理用户投诉举报，以共同营造风清气正的网络空间。
    </div>
    <div class="title big">5、用户个人信息保护</div>
    <div class="content">
      5.1 个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。您在下载、安装、启动、浏览、注册、登录、使用皮可米产品与或服务时，皮可米将按照平台公布的《皮可米隐私政策》的约定处理和保护您的个人信息，因此希望您能够仔细阅读、充分理解《皮可米隐私政策》的全文，并在需要时，按照《皮可米隐私政策》的指引，做出您认为适当的选择。
    </div>
    <div class="content">
      5.2 您在注册账号或使用“皮可米”产品的过程中，可能需要填写一些必要的信息。若国家法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，则无法使用相关产品和服务或在使用过程中受到限制。
    </div>
    <div class="content">
      5.3 您可随时浏览、修改自己提交的个人身份信息，您理解并同意出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
    </div>
    <div class="content">
      5.4 皮可米将运用各种与公司所提供服务相匹配的安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。用户理解并同意，由于互联网的开放性以及技术更新迅速，因非皮可米可控制的因素导致用户信息泄漏的，皮可米不承担责任。
    </div>
    <div class="content">
      5.5 非因用户同意，皮可米不会将您的个人信息转移或披露给任何非关联的第三方，除非：
    </div>
    <div class="content">（1）相关法律法规或司法、行政等国家机关要求；</div>
    <div class="content">（2）为完成合并、分立、收购或资产转让而转移；</div>
    <div class="content">（3）皮可米及其关联公司为提供您要求的服务所必需。</div>
    <div class="content">
      5.6 用户理解并同意，以下情况皮可米有权使用用户的个人信息：
    </div>
    <div class="content">
      （1）在进行促销、抽奖等商业推广活动时，因活动需要皮可米可能会与商业合作伙伴共享用户的部分个人信息，皮可米会在发送相关信息前对用户进行提示，用户不同意上述信息共享的，可通过不参与促销或抽奖活动来终止信息传送过程；
    </div>
    <div class="content">
      （2）皮可米可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与皮可米同等的保护用户隐私的责任，则皮可米有权将用户的注册资料等提供给该第三方。用户理解并同意，若第三方未经皮可米知晓并同意，恶意或超范围使用用户个人信息的，由该第三方向用户承担相应赔偿责任，皮可米将协助用户进行相关维权工作；
    </div>
    <div class="content">
      （3）在不透露单个用户隐私资料的前提下，皮可米有权对整个用户数据库进行分析并对相关分析数据进行商业上的使用。
    </div>
    <div class="content">
      皮可米会利用您提交的电子邮件用于发送与皮可米有关的通知。除前述使用以及您个人行为或同意披露外，皮可米不会在网络中向任何第三方显示或共享您的电子邮件信息。
    </div>
    <div class="content">
      5.7
      请用户注意勿在使用皮可米软件服务中透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由用户自行承担。
    </div>
    <div class="content">
      就下列相关事宜的发生，皮可米软件不承担任何法律责任：
    </div>
    <div class="content">
      （1）皮可米根据法律规定或相关政府机关、安全机关、司法机关的要求提供用户的个人信息；
    </div>
    <div class="content">
      （2）由于用户将用户密码告知他人或与他人共享注册账户，由此导致的任何财产损失、虚拟财产损失、个人信息的泄漏，或其他非因皮可米原因导致的上述损失或泄漏；
    </div>
    <div class="content">
      （3）任何由于黑客攻击、电脑病毒侵入造成的任何财产损失、虚拟财产损失、信息泄漏；
    </div>
    <div class="content">
      （4）在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行动等。出现上述情况时。我们将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，我们及合作单位在法律允许的范围内免责。
    </div>
    <div class="content">
      （5）在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，我们不承担任何责任。
    </div>
    <div class="content">
      （6）我们需要定期或不定期地对皮可米平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，我们无需为此承担任何责任，但我们应事先进行通告。
    </div>
    <div class="title big">6、服务的变更、中断和终止</div>
    <div class="content">
      6.1 您理解并同意，皮可米提供的“皮可米”产品和服务是按照现有技术和条件所能达到的现状提供的。皮可米会尽最大努力向您提供服务，确保服务的连贯性和安全性；但皮可米不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
    </div>
    <div class="content">
      6.2 用户理解并同意，皮可米为了服务整体运营的需要，有权在公告通知后，在不事先通知用户的情况下修改、中断、中止或终止“皮可米”产品内的各项服务，而无须向用户或第三方负责或承担任何赔偿责任。
    </div>
    <div class="content">
      6.3 若您提交的注册信息和材料不真实、不完整、不合法或无效，那么因此产生的一切法律责任由您承担。皮可米有权随时封禁或删除您的账号，以及中止或终止为您提供皮可米的相关服务。
    </div>
    <div class="content">
      6.4 若皮可米发现您不当使用账号或因您的账号被他人举报投诉时，皮可米有权不经通知随时删除相关内容，并视行为情节对违规账号进行处理，处理方式包括但不限于警告、删除部分或全部订阅用户、限制或禁止使用全部或部分功能、账号封禁甚至注销，并有权视具体情况而公告处理结果。
    </div>
    <div class="content">
      6.5 发生上诉6.3、6.4条所述之终止的情况时，您与皮可米均认可如下处理方式：
    </div>
    <div class="content">
      （1）您的账号下已经产生但未使用的权益自动清除且不折现；
    </div>
    <div class="content">
      （2）如您在皮可米的平台内有正在进行中的交易，皮可米届时将视情况进行合理处理；
    </div>
    <div class="content">
      （3）除法律法规另有规定或皮可米另有说明外，皮可米已收取的相关费用不予退还；
    </div>
    <div class="content">
      （4）如因您违反本协议导致终止的，皮可米有权视情况要求您承担相应的违约责任；
    </div>
    <div class="content">
      （5）除法律法规另有规定或皮可米另有说明外，皮可米无需向您和第三人承担任何责任。
    </div>
    <div class="title big">7、账号注销</div>
    <div class="content">
      我们为您提供皮可米账号注销权限，您可以通过联系我们客服的方式注销您的账号（但法律法规另有规定的或本政策另有约定的除外），一旦您注销账号，将无法使用我们提供的产品和服务且自动放弃已有的权益，因此请您谨慎操作。除法律法规另有规定外，注销账号之后，我们将停止为您提供我们所有的产品和服务，您曾通过该账号使用的我们的产品与服务下的所有内容、信息、数据、记录将会被删除或匿名化处理。
    </div>
    <div class="content">
      您需要首先知悉的是：在您的皮可米账号注销后，将（可能）产生包括但不限于如下结果需要您自行承担：
    </div>
    <div class="content">
      7.1 皮可米账号一旦注销，您将无法登录、使用该账号，也就是说您将无法再以此账号登录、使用、继续使用皮可米所有产品与服务；
    </div>
    <div class="content">
      7.2 皮可米账号一旦注销，您曾通过该账号登录、使用的皮可米的产品与服务下的所有内容、信息、数据、记录将会被全部删除或匿名化处理，您也无法再检索、访问、获取、继续使用和找回，也无权要求我们找回（但法律法规另有约定的除外），包括但不限于：
    </div>
    <div class="content">
       • 该账号下的个人资料（例如：头像、昵称等）及绑定信息（例如：绑定手机号、邮箱等）；
    </div>
    <div class="content">
       • 该账号下的您的个人隐私信息（同时您亦不再享有《皮可米隐私政策》中约定的您的用户权利，但法律法规另有约定的除外）；
    </div>
    <div class="content">
       • 该账号曾发表的所有内容（例如：文章、图片、评论、互动等）；
    </div>
    <div class="content">
       • 其他所有内容、信息、数据、记录（例如：收藏等）。
    </div>
    <div class="content">
      7.3 您同意通过注销账号的方式放弃该账号在皮可米旗下的产品与服务使用期间已产生的但未消耗完毕的权益及未来的逾期利益。即皮可米账号一旦注销，我们将会对该账号下的全部权益做清除处理，包括但不限于：
    </div>
    <div class="content"> • 您尚未提现的账户余额；</div>
    <div class="content">
      • 您在皮可米旗下的产品和服务中发布的内容未来的收益权益；
    </div>
    <div class="content"> • 您在皮可米旗下的产品和服务中的各类身份权益；</div>
    <div class="content"> • 其他已产生但未消耗完毕的权益或未来预期的收益。</div>
    <div class="content">
      7.4 皮可米账号一旦注销，将无法恢复（即使您在注销后以相同手机号码再次向我们申请注册皮可米账号，此时该账号将默认为新的用户账号。因此，我们善意地提醒您在申请注销前对需要备份的内容提前自行做好备份）；
    </div>
    <div class="content">
      7.5 皮可米账号一旦注销，您与我们曾签署过的相关用户协议、其他权利义务性文件等相应终止（但已约定继续生效的或法律法规另有规定的除外）；
    </div>
    <div class="content">7.6 其他因皮可米账号注销（可能）产生的结果。</div>
    <div class="title big">8、知识产权</div>
    <div class="content">
      8.1 未经相关权利人同意，用户不得对“皮可米”的产品和服务涉及的相关网页、应用、软件等产品进行反向工程、反向汇编、反向编译等。
    </div>
    <div class="content">
      8.2 用户理解并同意：在使用“皮可米”产品和服务时发表上传的文字、图片、视频、软件等用户原创的信息，此知识产权归属用户，但用户的发表、上传行为同时代表该信息对皮可米在全世界范围内非独占性、不可撤销的、免费的、永久性和可转授权地使用，使用范围包括但不限于在皮可米当前网站、移动客户端应用程序或其他终端设备等，并授权皮可米及其关联公司对相应内容可进行修改、复制、改编、翻译、汇编或制作衍生产品。
    </div>
    <div class="content">
      8.3 皮可米尊重并保护法人、公民的知识产权、名誉权、姓名权、隐私权等合法权益。用户应保证，在使用“皮可米”的产品和服务时上传的文字、图片、视频、软件以及表演等的信息不侵犯任何第三方的知识产权、名誉权案、姓名权、隐私权等合法权益。否则，皮可米有权移除该侵权信息。前述第三方提出的全部权利主张，用户应自行承担全部赔偿责任，与皮可米无关，皮可米因此遭受的包括但不限于经济、企业商誉等损失，均由该用户承担。
    </div>
    <div class="content">
      8.4 皮可米在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权属于皮可米所有。皮可米提供本服务时所依托的软件的著作权、专利权及其他知识产权均归皮可米所有。
    </div>
    <div class="content">
      8.5 皮可米为皮可米的开发、运营提供技术支持，并对皮可米产品及服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。
    </div>
    <div class="content">
      8.6 请您在任何情况下都不要私自使用皮可米的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为“标识”）。未经皮可米事先书面同意，您不得将本条款签署标识以任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于您非法使用皮可米上述商标、标识等给皮可米或他人造成损失的，由您承担全部法律责任。
    </div>
    <div class="title big">9、法律责任声明</div>
    <div class="content">
      9.1 皮可米对于任何自皮可米而获得的他人的信息、内容或者广告宣传等任何资讯(以下统称“信息”)，不负保证真实、准确和完整性的责任。如果任何单位或者个人通过上述“信息”而进行任何行为，须自行甄别真伪和谨慎预防风险，否则，无论何种原因，皮可米不对任何非与本网站直接发生的交易和(或)行为承担任何直接、间接、附带或衍生的损失和责任。
    </div>
    <div class="content">9.2 皮可米不保证(包括但不限于)：</div>
    <div class="content">（1）皮可米完全适合用户的使用要求；</div>
    <div class="content">
      （2）皮可米不受干扰，及时、安全、可靠或不出现错误；用户经由皮可米取得的任何产品、服务或其他材料符合用户的期望；
    </div>
    <div class="content">（3）软件中任何错误都将能得到更正。</div>
    <div class="content">
      9.3 基于以下原因而造成的利润、商业信誉、资料损失或其他有形或无形损失，皮可米不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任（即使皮可米事先已被告知发生此种赔偿之可能性亦然)：
    </div>
    <div class="content">（1）对皮可米的使用或无法使用；</div>
    <div class="content">
      （2）皮可米不受干扰，及时、安全、可靠或不出现错误；用户经由皮可米取得的任何产品、服务或其他材料符合用户的期望；
    </div>
    <div class="content">（3）软件中任何错误都将能得到更正。</div>
    <div class="content">
      （4）任何第三方在皮可米产品和服务中所做的声明或行为；
    </div>
    <div class="content">
      （5）用户基于在“皮可米”中发布的广告信息，向第三方购买商品或服务；
    </div>
    <div class="content">
      （6）其他与皮可米相关的事宜，但本用户协议有明确规定的除外。
    </div>
    <div class="title big"> 10、违约责任</div>
    <div class="content">
      用户因违反国家法律法规或本协议的约定或用户侵害他人任何权利因而衍生或导致任何第三人向皮可米提出任何索赔或请求，包括但不限于诉讼费用、律师费用、差旅费用、和解金额、罚款或生效法律文书中规定的损害赔偿金额、软件使用费等而给皮可米造成损失的，用户应赔偿皮可米因此而遭受的一切损失，并消除影响。
    </div>
    <div class="title big">11、其他条款</div>
    <div class="content">
      11.1 本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国法律。倘本协议之任何规定因与中华人民共和国法律抵触而无效，则这些条款将尽可能接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。本协议的签署地点为中华人民共和国上海市，若用户与皮可米发生争议的，双方应尽量友好协商解决，协商不成，任何一方均应将争议提交上海市徐汇区法院诉讼解决。
    </div>
    <div class="content">
      11.2 本协议可能因国家政策、产品、履行环境发生变化或皮可米的需要而进行修改，皮可米会将修改后的协议发布在网站上。若您对修改后的协议有异议的，请立即停止登录、使用“皮可米”产品及服务，若您登录或继续使用“皮可米”产品及服务，视为认可修改后的协议。
    </div>
    <div class="content">
      11.3 您和皮可米均是独立的主体，在任何情况下本协议不构成双方之间的代理、合伙、合营或雇佣关系。
    </div>
    <div class="content">
      11.4我们有权根据需要不时制订、修改本协议及相关规则，变更后的协议和规则将通过皮可米移动客户端以公告、弹窗、站内信、消息等的形式通知您，变更后的协议和规则一经通知即生效并取代原协议及规则。如您不同意相关变更，应当立即停止使用皮可米服务；如您继续使用皮可米服务或进行任何网站活动，即表示您已接受经修订的协议和规则。
    </div>
    <div class="content right">本声明自更新之日起生效</div>
    <div class="content right">最近的更新日期：2021年10月19日</div>
    <div class="content right" style="padding-bottom: 30px">
      杭州隅星互联网科技有限责任公司
    </div>
  </div>
</template>
<script>
export default {
  mounted(){
    window.scrollTo(0, 0)
  }
};
</script>
<style scoped>
.container {
  padding: 0 30px;
}
.title {
  font-weight: 600;
  padding: 8px 0;
}
.content {
  margin-bottom: 5px;
}
.right {
  text-align: right;
  margin-top: 15px;
}
.big {
  font-size: 22px;
}
</style>